import $ from "jquery/dist/jquery"
class Common {
  constructor() {
    var CI = this;
    this.currentTab = 0;
    this.formValidation = {}
    this.ip_Address = '';
    // $.getJSON('https://ipapi.co/json/', function(data) {
    //   if (data != null && data.ip != undefined && typeof (data.ip) == "string") {
    //     CI.ip_Address = data.ip;
    //   }
    // });
    window.Parsley.on('field:error', function() {
      $(".btn-progress").removeClass("in-progress")
    });

    window.FontAwesomeConfig = {
      searchPseudoElements: true
    }
  }

  showToolTip(){
    $('[data-toggle="tooltip"]').tooltip();
  }

  getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  }

  getFormattedCurrentDate() {
    var date = new Date();
    var day = this.addZero(date.getDate());
    var monthIndex = this.addZero(date.getMonth() + 1);
    var year = date.getFullYear();
    var min = this.addZero(date.getMinutes());
    var hr = this.addZero(date.getHours());
    var ss = this.addZero(date.getSeconds());

    return day + '/' + monthIndex + '/' + year + ' ' + hr + ':' + min + ':' + ss;
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  validate(form){
    this.formValidation = $("#dealform").parsley({
        trigger: "focusout",
        errorClass: 'error',
        successClass: 'valid',
        errorsWrapper: '<div class="parsley-error-list"></div>',
        errorTemplate: '<label class="error"></label>',
        errorsContainer (field) {
          if(field.$element.hasClass('approve')){
            return $('.error-checkbox')
          }
          if(field.$element.hasClass('inputFName')){
            return $('.error-fname')
          }
          if(field.$element.hasClass('nextpay')){
            return $('.error-nextpay')
          }
          if(field.$element.hasClass('inputLName')){
            return $('.error-lname')
          }
          if(field.$element.hasClass('nextpayafter')){
            return $('.error-nextpayafter')
          }
          
          return field.$element.parent()
        },
    })
    this.formValidation = $("#loanform").parsley({
        trigger: "focusout",
        errorClass: 'error',
        successClass: 'valid',
        errorsWrapper: '<div class="parsley-error-list"></div>',
        errorTemplate: '<label class="error"></label>',
        errorsContainer (field) {
          if(field.$element.hasClass('approve')){
            return $('.error-checkbox')
          }
          return field.$element.parent()
        },
    })
    this.validatePhone()
    this.validatePostcode()
  }

  validatePhone() {
    var CI = this;
    window.Parsley.addValidator("validphone", {
      validateString: function(value) {
        var phoneNum = $(".phone").val()
        var deferred = $.Deferred();
        $.ajax({
            url: "https://conversionhero.io/api/v1/validation/mobile",
            type: "POST",
            headers: {
              "Authorization": "Bearer U3QfZmAKPbusatllWrRYOE6AmrsNnt3X7NvaV1ca7979f935",
              "Content-Type": "application/x-www-form-urlencoded",
              "Accept": "application/json"
            },
            data: {
              "number": phoneNum,
              "country": "GB"
            }
        }).done(function(json) {
          var skipresponse = [
            "EC_ABSENT_SUBSCRIBER",
            "EC_ABSENT_SUBSCRIBER_SM",
            "EC_CALL_BARRED",
            "EC_SYSTEM_FAILURE",
            "EC_SM_DF_memoryCapacityExceeded",
            "EC_NO_RESPONSE",
            "EC_NNR_noTranslationForThisSpecificAddress",
            "EC_NNR_MTPfailure",
            "EC_NNR_networkCongestion",
          ];
          if (skipresponse.includes(json.response) || json.status == "VALID") {
            CI.isPhone = true;
            deferred.resolve();
          } else if (json.status == "INVALID" || json.status == "ERROR") {
            deferred.reject("Please Enter Valid UK Phone Number");
          } else {
            CI.isPhone = true;
            deferred.resolve();
          }
        }).fail(function() {
          CI.isPhone = true;
            deferred.resolve();
        });
        return deferred.promise();
      },
      messages: {
        en: "Please Enter Valid UK Phone Number",
      },
    });
  }


  validatePostcode(){
    window.Parsley.addValidator('validpostcode', {
      validateString: function(value){
      $(".tab").addClass("in-progress")
       var xhr = $.ajax(`https://api.getAddress.io/find/${$(".postcode").val()}?api-key=NjGHW5787&expand=true`)
        return xhr.then(function(json) {
          if (json.addresses.length > 0) {
            var result = json.addresses
            var adresses = []
             adresses.push( `
              <option
              disabled=""
              selected=""
              >
              Select Your Property
              </option>
            `)
            for (var i = 0; i < result.length; i++) {
              adresses.push( `
                  <option
                  data-street="${result[i].line_1}"
                  data-city="${result[i].town_or_city}"
                  data-province="${result[i].county}"
                  >
                  ${result[i].formatted_address.join(" ").replace(/\s+/g,' ')}
                  </option>
                `)
              }
              $('#property').html(adresses)
              $(".tab").removeClass("in-progress")
              $('#address').show()
              $('.city').val($("#property").find("option:selected").data("city"))
              $('.street').val($("#property").find("option:selected").data("street"))
              $('.county').val($("#property").find("option:selected").data("province"))

            return true
          }else{
            $(".tab").removeClass("in-progress")
            return $.Deferred().reject("Please Enter Valid Postcode");
          }
        })
      },
      messages: {
         en: 'Please Enter Valid Postcode',
      }
    });
  }
  showTab(n=0) {
    var tabs = $(".tab");
    if (!tabs[n]) return;
    tabs[n].style.display = "block";
    // this.fixStepIndicator(n)
    $(".btn-progress").removeClass("in-progress")
  }

  showCircle(){
    $(".btn-progress").addClass("in-progress")
  }

  fixStepIndicator(num) {
    var progress = document.getElementById('progressBar');
    if(num >= 0) {
      progress.style.width = (Math.ceil(num*4.5))+"%";
      $(".progress-percent").text((Math.ceil(num*4.5))+"%  Complete" );
    }
  }

  backStep(n){
    if (this.currentTab > 0) {
      var tabs = $(".tab");
      tabs[this.currentTab].style.display = "none";
      this.currentTab = this.currentTab + n;
      this.showTab(this.currentTab);
    }
  }

  nextStep(n) {
    this.showCircle();
    var CI = this;
    $('#dealform').parsley().whenValidate({
      group: 'block-' + this.currentTab
    }).done(() =>{
      var tabs = $(".tab");
      tabs[CI.currentTab].style.display = "none";
      CI.currentTab = CI.currentTab + n;
      if (CI.currentTab >= tabs.length) {
        if (CI.isPhone == true){
          CI.postData()
        }else{
          $('#dealform').parsley().validate()
        }
        return true
      }
      CI.showTab(CI.currentTab);
    })
  }

  getData() {
    var day = $( "#dayOfBirth option:selected" ).val();
    var month = $( "#monthOfBirth option:selected" ).val();
    var year = $( "#yearOfBirth option:selected" ).val();
    var dateofbirth = year + "-" + month + "-" +day;
    var fileInputPayslip = document.getElementById('pay_slip');
    var paySlip = fileInputPayslip.files[0];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const nextMonthDate = new Date(currentDate);
    nextMonthDate.setMonth(currentDate.getMonth() + 1);
    const nextMonthYear = nextMonthDate.getFullYear();

    var incomeNextDate1day = $( "#incomeNextDate1day option:selected" ).val();
    var incomeNextDate1month = $( "#incomeNextDate1month option:selected" ).val();
    var incomeNextDate1 = currentYear + "-" + incomeNextDate1month + "-" +incomeNextDate1day;
    var incomeNextDate2day = $( "#incomeNextDate2day option:selected" ).val();
    var incomeNextDate2month = $( "#incomeNextDate2month option:selected" ).val();
    var incomeNextDate2 = nextMonthYear + "-" + incomeNextDate2month + "-" +incomeNextDate2day;
    return {
      national_insurance_num: getUrlParameter('national_insurance_num') || $(".national_insurance_num").val() || '',
      job_title: getUrlParameter('job_title') || $(".job_title").val() || '',
      pay_slip: paySlip || '',
      documentPassword: getUrlParameter('document_password') || $(".document_password").val() || '',
      agreetax: $("input[name='agreetax']:checked"). val() || '0',
      thirdPartyPhone: $("input[name='thirdPartyPhone']:checked"). val() || '0',
      thirdPartyTextMessage: $("input[name='thirdPartyTextMessage']:checked"). val() || '0',
      thirdPartyEmail: $("input[name='thirdPartyEmail']:checked"). val() || '0',
      bankDebitCardType:this.getUrlParameter('card_type') || $("input[name='card_type']:checked"). val() || '',
      bankSortCode:this.getUrlParameter('bankSortCode') || $(".bankSortCode").val() || '',
      bankAccountNumber:this.getUrlParameter('bankAccountNumber') || $(".bankAccountNumber").val() || '',
      expenseFood:this.getUrlParameter('expenseFood') || $(".expenseFood").val() || '',
      expenseTransport:this.getUrlParameter('expenseTransport') || $(".expenseTransport").val() || '',
      expenseUtilities:this.getUrlParameter('expenseUtilities') || $(".expenseUtilities").val() || '',
      expenseOther:this.getUrlParameter('expenseOther') || $(".expenseOther").val() || '',
      expenseAllLoans:this.getUrlParameter('expenseAllLoans') || $(".expenseAllLoans").val() || '',
      expenseHousing:this.getUrlParameter('expenseHousing') || $(".expenseHousing").val() || '',
      workTimeAtEmployer:this.getUrlParameter('workTimeAtEmployer') || $( "#time_at_employer option:selected" ).val() || '',
      workIndustry:this.getUrlParameter('workIndustry') || $( "#employer_industry option:selected" ).val() || '',
      workCompanyName:this.getUrlParameter('workCompanyName') || $(".employer_name").val() || '',
      incomeNextDate2:this.getUrlParameter('incomeNextDate2') || incomeNextDate2 || '',
      incomeNextDate1:this.getUrlParameter('incomeNextDate1') || incomeNextDate1 || '',
      incomePaymentFrequency:this.getUrlParameter('pay_frequency') || $( "#pay_frequency option:selected" ).val() || '',
      bankDirectDeposit:this.getUrlParameter('bankDirectDeposit') || $("input[name='bankDirectDeposit']:checked"). val() || '',
      incomeNetMonthly:this.getUrlParameter('incomeNetMonthly') || $("input[name='monthlyIncome']"). val() || '',
      incomeType:this.getUrlParameter('incomeType') || $( "#employment_status option:selected" ).val() || '',
      monthsAtAddress: this.getUrlParameter('monthsAtAddress') || $( "#time_at_current_address option:selected" ).val() || '',
      residentialStatus:this.getUrlParameter('residentialStatus') || $("input[name='residential_status']:checked"). val() || '',
      numberOfDependents: this.getUrlParameter('numberOfDependents') || $( "#dependents option:selected" ).val() || '',
      maritalStatus: this.getUrlParameter('maritalStatus') || $("input[name='marital_status']:checked").val() || '',
      title: this.getUrlParameter('title') || $( "#title option:selected" ).val() || '',
      dob:dateofbirth,
      loanDurationMonths: this.getUrlParameter('loan_duration_months') || $( "#loan_duration_months option:selected" ).val() || "",
      loanAmount:this.getUrlParameter('loan_amount') || $( "#loan_amount option:selected" ).val() || '',
      loanPurpose: this.getUrlParameter('loanPurpose') || $( "#loan_purpose option:selected" ).val() || '',
      postCode: this.getUrlParameter('postcode') || $(".postcode").val() || '',
      street: this.getUrlParameter('street1') || $(".street_name").val() || '',
      city: this.getUrlParameter('towncity') || $(".towncity").val() || '',
      flatNumber: this.getUrlParameter('flatNumber') || $(".flat_number").val() || '',
      houseNumber: this.getUrlParameter('houseNumber') || $(".house_number").val() || '',
      houseName: this.getUrlParameter('houseName') || $(".house_number").val() || '',
      county:this.getUrlParameter('county') || $(".county").val() || '',
      firstName: this.getUrlParameter('firstname') || $(".firstname").val() || '',
      lastName: this.getUrlParameter('lastname') || $(".lastname").val() || '',
      email: this.getUrlParameter('email') || $("input[name='email']").val() || '',
      cellPhone: this.getUrlParameter('phone1') || $(".mobile_phone").val() || '',
      homePhone:this.getUrlParameter('homePhone') || $(".home_phone").val() || '',
      workPhone:this.getUrlParameter('workPhone') || $(".work_phone_number").val() || '',
      userIp: this.ip_Address|| "192.168.1.1",
      sid: this.getUrlParameter('sid') || 1,
      ssid: this.getUrlParameter('ssid') || 1,
      ad_set:this.getUrlParameter('ad_set') || 1,
      source: this.getUrlParameter('source') ||  'google3',
      c1: this.getUrlParameter('c1') || this.getUrlParameter('bstransid') || this.getUrlParameter('transid') || '',
      adgroupid: this.getUrlParameter('adgroupid') || '',
      campaign: this.getUrlParameter('campaign') || '',
      productId: "4",
      price: "15",
      testMode: this.getUrlParameter('testMode'),
      testSold: this.getUrlParameter('testSold'),
    };
  }

  postData() {
    var CI = this;
    var data = this.getData();
    console.log(data)
    // Form Submisson
    this.firePixel()
    this.submitLead(data)

    this.showloader()
  }

  showloader(){
    var CI = this;
    $(".progress-head").hide()
    $("#myNav").css('height', '100%')
  }

  firePixel(){
    dataLayer.push({'event': 'transaction'})
  }

  submitLead(data){
     var CI = this;
    $.ajax({
      type: "POST",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      url: "/deliveries",
      data: data,
      success: function(data) {
        if (data.response.status  == 1) {
          window.location = data.response.redirect_url
        }else if(data.response.status  == 2){
           window.location = data.response.rejectUrl
        }else{
          var errors = data.response.errors
          var errorlist = []
          for (var index = 0; index < errors.length; ++index) {
              errorlist.push(`<p>${Object.values(errors[index])[0]} </p>`)
          }
          $(".form-error").html(errorlist)
          $(".form-error").show()
          $(".progress-head").show()
          CI.currentTab = CI.currentTab - 1
          CI.backStep(-1)
          $("#myNav").css('height', '0%')
        }
        console.log(data)
      },
      dataType: "json"
    })
  }

}
export default Common;

